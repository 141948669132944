import { t } from 'services/utils/translation';
import IClientSelectOptionV2 from '../services/api/interfacesApi/IClientSelectOptionV2';
import { IPersonV2 } from '../services/interfaces/IPersonV2';
import AppYup from '../services/yup/validation';
import { regTelephoneNumber } from '../services/utils/myRegex/myRegex';
import { SELECTED_COUNTERPARTY_IN_TASK } from 'components/tasks/TaskForm/constants/selectedCounterparty';
import { IFormValuesTask } from '../components/tasks/TaskForm/TaskForm';
import { getCurrentDateToClientDate } from 'services/utils/dateHelper/dateHelper';
import { IChildInternalTask, ITaskListOfComment } from 'services/api/tasks/tasks/IGetPaginatedTasksPayload';
import { IChildExternalRequest } from 'services/api/external-request/external-request/IExternalRequest';

class Task implements IFormValuesTask {
  public taskPk?: number;
  public internal_task_parent_internal_task_fk?: number;
  public internal_task_new_comment?: string;
  public internal_task_reporter_phone_number?: string;
  public internal_task_date_duedate?: string;
  public status?: IClientSelectOptionV2<number>;
  public internal_task_description?: string;
  public taskType?: IClientSelectOptionV2<number>;
  public account?: IClientSelectOptionV2<number>;
  public internal_task_billing_account_fk?: number;
  public supervisor?: IClientSelectOptionV2<number>;
  public internal_task_time_adjustment?: number;
  public internal_task_author?: IPersonV2;
  public internal_task_parent_external_request_fk?: number;
  public counterparty?: IClientSelectOptionV2<number>;
  public selectedCounterparty?: SELECTED_COUNTERPARTY_IN_TASK =
    SELECTED_COUNTERPARTY_IN_TASK.selectedBillingAccount;
  static ADJUSTMENT_MAX: number = 1_000_000_000;
  public isSelectedAffiliate?: boolean = false;
  public isBillingAccountClosed?: boolean = false;
  //Свойства ниже никогда не используется для создания. Они нужны только для чтения
  public internal_task_list_of_comments?: ITaskListOfComment[];
  public internal_task_is_pruned?: boolean = false;
  public internal_task_comment_text?: string;
  public childInternalTasks?: IChildInternalTask[];
  public childExternalRequests?: IChildExternalRequest[];

  constructor(
    taskPk?: number,
    defaultStatus?: IClientSelectOptionV2<number>,
    billingAccountId?: number,
    counterpartyPk?: number
  ) {
    Task.handleCounterpartyDefault(counterpartyPk);
    Task.handleBillingAccountDefault(billingAccountId);
    this.taskPk = taskPk;
    this.status = defaultStatus;
    this.internal_task_billing_account_fk = billingAccountId;
    this.internal_task_date_duedate = getCurrentDateToClientDate();
  }

  private static handleCounterpartyDefault(
    counterpartyPk?: number
  ): IClientSelectOptionV2<number> | undefined {
    return counterpartyPk ? { label: '', value: counterpartyPk } : undefined;
  }

  private static handleBillingAccountDefault(
    billingAccountId?: number
  ): IClientSelectOptionV2<number> | undefined {
    return billingAccountId
      ? { label: '', value: billingAccountId }
      : undefined;
  }
  private static isClosedBillingAccount = (
    billingAccountIsClosed?: boolean,
    curValue?: IClientSelectOptionV2
  ) => {
    if (!curValue) {
      return true;
    }
    return !billingAccountIsClosed;
  };

  public static validationSchema = (
    isNew: boolean = false
  ): AppYup.ObjectSchema<any> => {
    return AppYup.object({
      taskPk: isNew
        ? AppYup.number()
        : AppYup.number().required(
            t('ID задачи не установлен. Свяжитесь с администратором.')
          ),
      internal_task_parent_internal_task_fk: AppYup.number().notRequired(),
      internal_task_: AppYup.string(),
      internal_task_reporter_phone_number: AppYup.string().matches(
        regTelephoneNumber,
        t('Некорректно введен номер телефона')
      ),
      internal_task_date_duedate: AppYup.string()
        .required(t('Укажите планируемую дату завершения.'))
        .dateValid()
        .dateNotEarlierThan(),
      taskType: AppYup.object().nullable().required(t('Укажите тип.')),
      account: AppYup.object()
        .requiredByConditionWithRef(
          {
            keyField: 'isBillingAccountClosed',
            getCondition: this.isClosedBillingAccount,
          },
          t(
            'Этот лицевой счет закрыт. Отредактируйте лицевой счет или выберите другой'
          )
        )
        .nullable(),
      supervisor: AppYup.object()
        .required(t('Укажите исполнителя.'))
        .nullable(),
      status: AppYup.object().required(t('Укажите статус.')).nullable(),

      internal_task_description: AppYup.string()
        .trim()
        .min(1, t('Текст не может быть короче 1 символа.'))
        .required(t('Укажите текст.')),
      internal_task_time_adjustment: AppYup.number()
        .nullable()
        .integer(t('Допустимо только целочисленное значение.'))
        .typeError('Допустимо только числовое значение.')
        .min(0, t('Не может быть меньше 0'))
        .max(Task.ADJUSTMENT_MAX, t('Максимальное значение 1_000_000_000.')),
    });
  };
}

export default Task;
