import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers/rootReducer';
import { initialState } from './initialStore/InitialState';
import { save, load } from 'redux-localstorage-simple';

const processEnv: string | undefined = process.env.NODE_ENV;

const paths = ['tasks', 'externalRequest'];

const middleware =
  processEnv === 'development'
    ? [thunk, logger, save({ states: paths })]
    : [
        thunk,
        save({
          states: paths,
          namespace: 'potok',
          namespaceSeparator: '::',
        }),
      ];

const persistedState = load({ states: paths });

const store = createStore(
  rootReducer,
  { ...initialState, ...persistedState },
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
