import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearTaskFormValues, setExitForm } from 'store/task/actions'; // Добавляем setExitForm
import {
  getTaskFormHasEntered,
  getTaskFormValuesFromStore,
} from 'store/task/selectors';
import isEqual from 'react-fast-compare';

// Вынесенная функция для извлечения ID задачи из пути
const getTaskIdFromPath = (path: string) => {
  const match = path.match(
    /\/internal-task-ensemble\/internal-task\/(\d+)\/edit/
  );
  return match ? match[1] : null;
};

const useTrackAndClearFormOnRouteChange = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const form = useSelector(getTaskFormValuesFromStore);
  const isEnteredForm = useSelector(getTaskFormHasEntered);

  const previousTaskIdRef = useRef<string | null>(null); // Храним ID предыдущей задачи
  const [clearTaskCalled, setClearTaskCalled] = useState(false); // Флаг предотвращения повторной очистки
  const [hasExited, setHasExited] = useState(false); // Флаг выхода из формы
  useEffect(() => {
    const currentTaskId = getTaskIdFromPath(location.pathname);
    const previousId = previousTaskIdRef.current;

    const shouldClearForm =
      previousId && (!currentTaskId || previousId !== currentTaskId);

    const formIsUnchanged = (currentValues: any, initialValues: any) =>
      !currentValues || isEqual(currentValues, initialValues);

    // Принудительный сброс формы при переходе на другую задачу
    if (shouldClearForm && !clearTaskCalled && hasExited) {
      const initialFormValues = form.values[previousId]?.initial;
      const currentFormValues = form.values[previousId]?.current;

      if (formIsUnchanged(currentFormValues, initialFormValues)) {
        dispatch(clearTaskFormValues(previousId));
        setClearTaskCalled(true);
      }
    }

    // Сбрасываем флаг выхода и очистки при переходе на новую задачу
    if (currentTaskId) {
      previousTaskIdRef.current = currentTaskId;
      setClearTaskCalled(false);
      setHasExited(false);
    }
  }, [
    location.pathname,
    form,
    dispatch,
    clearTaskCalled,
    hasExited,
    isEnteredForm,
  ]);

  // Принудительный вызов setExitForm перед сменой задачи
  useEffect(() => {
    const currentTaskId = getTaskIdFromPath(location.pathname);
    const previousId = previousTaskIdRef.current;

    if (previousId && previousId !== currentTaskId) {
      dispatch(setExitForm()); // Принудительно выходим из формы старой задачи
      setHasExited(true); // Фиксируем момент выхода
    }
  }, [location.pathname, dispatch]);
};

export default useTrackAndClearFormOnRouteChange;
