import Task from 'models/Task';
import { IFormValuesTask } from '../TaskForm';
import { useSelector } from 'react-redux';
import { getTaskFormValuesFromStore } from 'store/task/selectors';
import { NEW_TASK_KEY } from '../constants/NEW_TASK';

type UseGetTaskDefaultValuesProps = {
  taskPk?: number;
  initialValuesFromOrder?: IFormValuesTask;
  initialValuesFromParentTask?: IFormValuesTask | null;
  newTask: IFormValuesTask | Task;
  isDefaultAccountOrCounterparty: boolean;
};

const useGetTaskDefaultValues = ({
  taskPk,
  newTask,
  initialValuesFromOrder,
  initialValuesFromParentTask,
  isDefaultAccountOrCounterparty,
}: UseGetTaskDefaultValuesProps): IFormValuesTask | Task => {
  const formTaskValues = useSelector(getTaskFormValuesFromStore);
  const taskId = taskPk ?? NEW_TASK_KEY;
  const { current, initial } = formTaskValues.values[taskId] || {};

  if (initialValuesFromParentTask) {
    return initialValuesFromParentTask;
  }
  if (initialValuesFromOrder) {
    return initialValuesFromOrder;
  }
  if (!taskPk && !current) {
    return newTask;
  }
  if (isDefaultAccountOrCounterparty) {
    return newTask;
  }

  return current || initial || newTask;
};

export default useGetTaskDefaultValues;
