import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';
import { useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';
import { clearTaskFormValues, setExitForm } from 'store/task/actions';

const useManageTaskFormEntry = () => {
  const dispatch = useDispatch();
  const previousTaskIdRef = useRef<string | undefined>(undefined);
  const [currentFormValues, setCurrentFormValues] =
    useState<IFormValuesTask | null>(null);
  const [initialFormValues, setInitialFormValues] = useState<
    IFormValuesTask | undefined
  >(undefined);
  const currentTaskId = useGetIdFromPath();

  useEffect(() => {
    const previousTaskId = previousTaskIdRef.current;

    if (previousTaskId && currentTaskId !== previousTaskId) {
      dispatch(setExitForm()); // Отмечаем выход из предыдущей формы

      if (!currentFormValues || isEqual(initialFormValues, currentFormValues)) {
        dispatch(clearTaskFormValues(previousTaskId));
      }
    }

    previousTaskIdRef.current = currentTaskId;
  }, [currentFormValues, currentTaskId, dispatch, initialFormValues]);

  return { setCurrentFormValues, setInitialFormValues }; // Возвращаем функции для установки значений формы
};

export default useManageTaskFormEntry;
