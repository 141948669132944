import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import React, {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import DocumentsPage from 'pages/documents/DocumentsPage/DocumentsPage';
import { useDispatch, useSelector } from 'react-redux';

import {
  getHistoryTask,
  readTaskApiV2,
} from '../../../../services/api/tasks/tasks/tasksApiV2';
import { IGetHistoryTaskPayload } from '../../../../services/api/tasks/tasks/IGetHistoryTaskPayload';
import TaskHistory from 'components/tasks/TaskHistory';
import TaskForm, { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import toFormValuesTask from 'store/utils/toFormValuesTaskV2';
import { IGetTaskPayload } from '../../../../services/api/tasks/tasks/IGetTaskPayload';
import TaskHeader from 'components/tasks/TaskHeader/TaskHeader';
import useGetRefreshingData from '../../../../hooks/useGetRefreshingData';
import {
  TAB_QUERY_PARAMS,
  TAB_QUERY_KEY,
} from '../../../../services/pathConstants';
import useGetQueryParameter from 'hooks/useGetQueryParameter';
import PagerWithParameterSwitch from 'components/misc/Pagers/PagerWithParameterSwitch/PagerWithParameterSwitch';
import useHaveAccessPolicyToActs from '../../../../hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyTask } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyTask';
import { parentUIElement } from 'services/constants/parentUIElement';
import useGetCommonTabs from 'hooks/useGetCommonTabs/useGetCommonTabs';
import { useParams } from 'react-router-dom';
import useTaskSuccessHandler from '../hooks/useTaskSuccessHandler';
import {
  clearTaskFormDifValues,
  saveTaskCurrentValues,
  setInitialFormTask,
  setTaskFormDifValues,
} from 'store/task/actions';
import { getTaskFormValuesFromStore } from 'store/task/selectors';
import isEqual from 'react-fast-compare';
import taskFormValuesLocalization from 'services/localLocalization/taskFormValuesLocalization';
import { t } from 'services/utils/translation';
import useGetDifferentTransformValues, {
  TChangesValuesForm,
} from 'hooks/useDraftConflictDetector/useGettDifferentValues';
import useGetDifferentValues from 'hooks/useGetDifferentValues/useGetDifferentValues';
import { BootstrapColors } from 'services/constants/BootstrapColors';

import styles from './index.module.scss';
import useManageTaskFormEntry from '../hooks/useManageTaskFormEntry';
import AnimatedAlertWrapper from 'components/misc/AlertWithAnimationWrapper/AlertWithAnimationWrapper';

const DRAFT_CONFLICT_TEXT = t(
  'Другой пользователь внёс изменения в эту заявку. Ниже указаны значения, которые вы не сохранили, и новые значения, внесённые другим пользователем'
);

const LATEST_CHANGES_TEXT = t('Последние изменения других пользователей');

const convertHistoryData = (data: IGetHistoryTaskPayload) => ({
  rows: data?.internal_task_list_of_edits,
  creationInfo: {
    internal_task_datetime_added: data?.internal_task_datetime_added,
    internal_task_author_dk: data?.internal_task_author_dk,
    internal_task_author: data?.internal_task_author,
  },
});

type TaskEditPagePropsType = {
  isDuplicate?: boolean;
  isChild?: boolean;
};

type PropsType = PropsWithChildren<TaskEditPagePropsType>;

const TaskEditPage: FC<PropsType> = (props) => {
  const dispatch = useDispatch();

  const { isDuplicate, isChild } = props;

  const [changedOnServerNotEditedByUser, setChangedOnServerNotEditedByUser] =
    useState<TChangesValuesForm[]>([]);

  const { id } = useParams();

  const pk = Number(id);
  const form = useSelector(getTaskFormValuesFromStore);

  const initial = form.values[pk]?.initial;
  const current = form.values[pk]?.current;
  const difValuesFromStore = form.values[pk]?.difValues;

  const getDifferentValues = useGetDifferentTransformValues({
    localization: taskFormValuesLocalization,
  });

  const prepareValuesForComparison = useCallback(
    (currentValues: IFormValuesTask) => {
      const newObj = { ...currentValues };
      delete newObj?.internal_task_comment_text;
      return newObj;
    },
    []
  );

  const getDifferentTaskValues = useGetDifferentValues();

  const { setCurrentFormValues, setInitialFormValues } =
    useManageTaskFormEntry();

  //TODO: NEED REFACTORING
  const convertTaskAndSetInitial = (data: IGetTaskPayload) => {
    const formValuesTask = toFormValuesTask(
      data?.internal_task,
      isDuplicate,
      isChild
    );

    const preparedInitialValues = prepareValuesForComparison(initial);
    const preparedCurrentValues = prepareValuesForComparison(current);
    const preparedFormValuesTask = prepareValuesForComparison(formValuesTask);

    const isTaskEdited = () => !initial || !isEqual(initial, formValuesTask);

    const updateServerChangedValues = () => {
      const { changedOnServerNotEditedByUser, editedButNotSaved } =
        getDifferentValues(
          preparedInitialValues,
          preparedCurrentValues,
          preparedFormValuesTask
        );

      setChangedOnServerNotEditedByUser(changedOnServerNotEditedByUser);

      if (editedButNotSaved.length > 0) {
        dispatch(setTaskFormDifValues(pk + '')(editedButNotSaved));
        const differentInitial = getDifferentTaskValues(
          preparedInitialValues,
          current
        );

        if (Object.keys(differentInitial).length > 0) {
          dispatch(
            saveTaskCurrentValues(pk + '')({
              ...current,
              ...preparedFormValuesTask,
              ...differentInitial,
            })
          );
        }
      }
    };

    if (isTaskEdited() && formValuesTask.taskPk && current?.taskPk) {
      updateServerChangedValues();
    }

    setInitialFormValues(formValuesTask);
    dispatch(setInitialFormTask(pk + '')(formValuesTask));

    return formValuesTask;
  };

  const {
    setRefreshDocuments: refreshTabs,
    isLoadingDocument: isLoadingCountTabs,
    commonTabs: tabs,
  } = useGetCommonTabs({
    parentUIElement: parentUIElement.task,
    id: pk,
  });

  const successHandler = useTaskSuccessHandler();

  const [haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyTask,
    targetAction: ['U'],
  });

  const initialParams = useMemo(
    () => ({
      pk,
    }),
    [pk]
  );

  const {
    data: historyData,
    refreshData: refreshHistory,
    isLoading: isLoadingHistory,
  } = useGetRefreshingData({
    getDataApi: getHistoryTask,
    initialData: null,
    params: initialParams,
    condition: !!pk,
    convertData: convertHistoryData,
  });

  const {
    data: task,
    isLoading: isLoadingTask,
    refreshData: refreshTask,
  } = useGetRefreshingData({
    getDataApi: readTaskApiV2,
    initialData: null,
    params: initialParams,
    condition: !!pk,
    convertData: convertTaskAndSetInitial,
  });
  //useClearFormValuesTaskStore(pk);
  const isPruned: boolean = !!task?.internal_task_is_pruned;

  const viewOnly = !haveAccessToUpdate || isPruned;

  const getChangeValuess = useCallback(
    (changeValues: TChangesValuesForm[]) => (
      <ul>
        {changeValues.map(({ nameProp, prevValue, newValue }, index) => (
          <li key={index}>
            <strong>{nameProp}:</strong> {t('было')} "{prevValue}",{t('стало')}{' '}
            "{newValue}"
          </li>
        ))}
      </ul>
    ),
    []
  );
  const CONTENT_MAP = {
    [TAB_QUERY_PARAMS.edit]: (
      <TaskForm
        successHandler={successHandler}
        taskPk={pk}
        viewOnly={viewOnly}
        refreshHistory={refreshHistory}
        isLoadingTask={isLoadingTask}
        actualTask={task}
        setCurrentFormValues={setCurrentFormValues}
      />
    ),
    [TAB_QUERY_PARAMS.history]: (
      <TaskHistory
        taskListOfHistoryEntity={historyData?.rows}
        creationTaskInfo={historyData?.creationInfo}
        isLoading={isLoadingHistory}
      />
    ),
    [TAB_QUERY_PARAMS.documents]: (
      <DocumentsPage
        parentUIName={parentUIElement.task}
        parentUIPk={pk}
        refreshTabs={refreshTabs}
        viewOnly={viewOnly}
      />
    ),
  };

  const targetTab =
    (useGetQueryParameter(TAB_QUERY_KEY.ticket) as keyof typeof CONTENT_MAP) ||
    TAB_QUERY_PARAMS.edit;

  const ContentForRender = CONTENT_MAP[targetTab];
  return (
    <MainLayout>
      <Subheader className={styles.topHeader} />
      <div className={styles.taskHeaderContainer}>
        <TaskHeader taskPk={pk} task={task} refreshForm={refreshTask} />
        <PagerWithParameterSwitch
          items={tabs}
          targetTab={targetTab}
          isLoadingCountTabs={isLoadingCountTabs}
        />
      </div>
      {changedOnServerNotEditedByUser.length > 0 && (
        <AnimatedAlertWrapper
          text={LATEST_CHANGES_TEXT}
          helpText={getChangeValuess(changedOnServerNotEditedByUser)}
          onAgree={{
            onAgreeText: t('Скрыть'),
            onAgreeCall: () => {
              setChangedOnServerNotEditedByUser([]);
            },
          }}
          color={BootstrapColors.info}
          show={!!task}
        />
      )}

      {difValuesFromStore?.length > 0 && (
        <AnimatedAlertWrapper
          text={DRAFT_CONFLICT_TEXT}
          helpText={getChangeValuess(difValuesFromStore)}
          onAgree={{
            onAgreeText: t('Принять изменения другого пользователя'),
            onAgreeCall: () => {
              task && dispatch(saveTaskCurrentValues(pk + '')(task));
              dispatch(clearTaskFormDifValues(pk + ''));
            },
          }}
          color={BootstrapColors.warning}
        />
      )}
      {ContentForRender}
    </MainLayout>
  );
};

export default memo(TaskEditPage);
