export const TASKS_SAVE_FILTERS = 'TASKS_SAVE_FILTERS';
export const SET_MARKED_TASK: string = 'SET_MARKED_TASK';
export const TASKS_CLEAR_FILTERS: string = 'TASKS_CLEAR_FILTERS';
export const SET_DEFAULT_STATUS = 'SET_DEFAULT_STATUS';
export const SET_IS_ACTIVE_COUNTERPARTY_FILTER =
  'SET_IS_ACTIVE_COUNTERPARTY_FILTER';
export const SET_ACCESS_POLICY_TASK = 'SET_ACCESS_POLICY_TASK';
export const SET_TASK_CURRENT_VALUES = 'SET_TASK_CURRENT_VALUES';
export const CLEAR_TASK_CURRENT_VALUES = 'CLEAR_TASK_CURRENT_VALUES';
export const CLEAR_TASK_INITIAL_VALUES = 'CLEAR_TASK_INITIAL_VALUES';
export const SET_TASK_DIF_VALUES = 'SET_TASK_DIF_VALUES';
export const CLEAR_TASK_DIF_VALUES = 'CLEAR_TASK_DIF_VALUES';

export const CLEAR_TASK_VALUES = 'CLEAR_TASK_VALUES';
export const SET_INITIAL_TASK_FIELD_VALUES = 'SET_INITIAL_TASK_FIELD_VALUES';

export const SET_ENTERED_IN_FORM = 'SET_ENTERED_IN_FORM';
export const SET_EXIT_FORM = 'SET_EXIT_FORM';

export const SET_INITIAL_NEW_TASK = 'SET_INITIAL_NEW_TASK'